import React from "react";
import Home from "./Home/Home";

export default function Admin() {
  return (
    <div>
      <Home />
    </div>
  );
}
