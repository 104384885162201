import React from "react";
import Form from "./Form";
import "./Home.scss";

function Home() {
  //
  return (
    <div className="flex justify-center items-center h-screen">
      <Form />
    </div>
  );
}

export default Home;
