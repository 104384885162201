import "./scss/main.scss";
import { useCookies } from "react-cookie";
//LoginSignup imports

function App() {
  const [cookies, setCookie] = useCookies(["token"]);
  
  return (
 <></>
  );
}

export default App;
