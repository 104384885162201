import React from 'react'
import Logo from "../assets/Logo11.svg";
import FlowHeader from './FlowHeader';


function Header() {
  return (
   <div>
    <FlowHeader/>
   </div>
  );
}

export default Header


