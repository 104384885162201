import React from 'react';

function Login() {
    //
    return (
        <div>
            <h2>login page</h2>
        </div>
    );
}

export default Login;
